<template>
  <header class="header" v-if="isDisplay">
    <v-btn text class="header__btn" v-if="type.includes('back')" @click="back()">
      <v-icon class="header__btn__icon">arrow_back</v-icon>
    </v-btn>
    <v-img :class="pageName === 'top' ? 'header__logo--top' : 'header__logo'" v-if="type.includes('logo') && logoImage" :src="logoImage.icon" contain />
    <p class="header__title" v-else-if="type.includes('logo')">{{ serviceName }}</p>
    <v-btn text class="header__login" v-if="pageName === 'top'" @click="signin">ログイン</v-btn>
    <p class="header__title" v-if="type.includes('title')">{{ title }}</p>
    <v-btn v-if="type.includes('menu')" text class="header__menu"
           @click="pageName === 'menu' ? back() : $router.push({ name: 'menu' })">
      <v-icon class="header__menu__icon">{{ pageName === 'menu' ? 'close' : 'menu' }}</v-icon>
    </v-btn>
  </header>
</template>

<script>
// ヘッダーを表示させないページ名
const NOT_DISPLAY = [
  'signup_group',
  'signup_done',
  'ticket_done',
  'account',
  'login',
  'discord',
  'error',
  'notfound',
  'notsupported',
  'stop',
  'maintenance'
]

// ヘッダーの詳細
// type: back, logo, title
const HEADER_DETAIL = {
  top: {
    type: ['logo']
  },
  signup: {
    type: ['title', 'back'],
    title: 'ご入会の案内'
  },
  signup_invitation: {
    type: ['logo', 'back']
  },
  signup_user: {
    type: ['title', 'back'],
    title: '基本情報'
  },
  signup_card: {
    type: ['title', 'back'],
    title: 'カード情報'
  },
  profile: {
    type: ['title', 'back'],
    title: 'プロフィール'
  },
  profile_edit: {
    type: ['title', 'back'],
    title: 'プロフィールの編集'
  },
  home: {
    type: ['logo', 'menu']
  },
  member: {
    type: ['title', 'back', 'menu'],
    title: '会員一覧'
  },
  ticket: {
    type: ['title', 'back', 'menu'],
    title: 'ご利用可能な回数券'
  },
  ticket_available: {
    type: ['title', 'back', 'menu'],
    title: '利用可能な回数券'
  },
  ticket_histories: {
    type: ['title', 'back', 'menu'],
    title: '回数券のご利用履歴'
  },
  payment_plan: {
    type: ['title', 'back', 'menu'],
    title: '登録中のプラン'
  },
  payment_card_change: {
    type: ['title', 'back'],
    title: 'カード情報の変更'
  },
  menu: {
    type: ['logo', 'menu']
  },
  terms: {
    type: ['title', 'back'],
    title: '利用規約'
  },
  policy: {
    type: ['title', 'back'],
    title: 'プライバシーポリシー'
  },
  law: {
    type: ['title', 'back'],
    title: '特定商取引法に基づく表記'
  },
  faq: {
    type: ['title', 'back'],
    title: 'よくある質問（FAQ）'
  },
  cancel: {
    type: ['title', 'back'],
    title: '退会'
  }
}

export default {
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示させるかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return this.pageName && HEADER_DETAIL[this.pageName].title ? HEADER_DETAIL[this.pageName].title : ''
    },
    /**
     * @return {String} ヘッダーのタイプ
     */
    type () {
      return this.pageName && HEADER_DETAIL[this.pageName].type ? HEADER_DETAIL[this.pageName].type : ''
    },
    /**
     * @return {Object} ロゴの画像
     */
    logoImage () {
      return this.$store.getters['settingImages/settingImage']('logo')
    },
    /**
     * @return {String} サービス名
     */
    serviceName () {
      return this.$store.getters['settingGeneral/settingGeneral']('serviceName').value
    }
  },
  methods: {
    /**
     * 前のページに戻る
     * 直リンクなどで前のページが存在しない場合はホームへ遷移させる
     */
    back () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'home' })
      }
    },
    /**
     * ログイン
     */
    signin () {
      this.$store.commit('setSubmitting', true)
      /**
       * アカウントの存在の確認などは、onAuthで実施
       * @todo 処理に応じて適宜、引数のプロバイダ名を変更する
       */
      this.$store.dispatch('auth/signin', 'google')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.header {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $header_zindex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: $max_width;
  height: $header_height;
  text-align: center;
  background-color: $white_color;
  transform: translate(-50%, 0);
  &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: 20px;
      padding: 0;
      color: $black_lighten_color;
    }
    &__icon {
      width: 20px;
      height: 20px;
      &.v-icon {
        font-size: 2rem;
      }
    }
  }
  &__logo {
    width: 50%;
    max-width: 157px;
    max-height: 30px;
    margin: 0 auto;
    &--top {
      @extend .header__logo;
      margin-left: $padding_width;
    }
  }
  &__login {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-weight: bold;
    &.v-btn:not(.v-btn--round).v-size--default {
      padding: 0 $padding_width;
      font-size: 1.2rem;
      color: $black_lighten_color;
    }
  }
  &__title {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  &__menu {
    position: absolute;
    top: 0;
    right: $padding_width;
    bottom: 0;
    margin: auto;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: 30px;
      padding: 0;
      color: $black_lighten_color;
    }
    &__icon {
      width: 30px;
      height: 30px;
      &.v-icon {
        font-size: 3rem;
      }
    }
  }
}
</style>
