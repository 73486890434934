<template>
  <div class="home" v-if="!isProcessing">
    <h2 class="home__title">SERVICE</h2>
    <home-menu class="home__menu" />
  </div>
</template>

<script>
import HomeMenu from '@/components/home/menu'

export default {
  components: { HomeMenu },
  name: 'home',
  async mounted () {
    const promises = []

    // 限定グループURLが未取得なら取得
    if (!this.groupURL) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'groupURL'))

    // Discord連携設定していない判定のときは最新の情報を取得する
    if (!this.isDiscordLinkage) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'discordServerID'))

    await Promise.all(promises)

    // Discord連携済みだがDiscordサーバーに参加していないユーザーは最新の参加情報を取得する
    if (this.isDiscordLinkage && !this.isJoinedDiscordServer) await this.$store.dispatch('discordUsers/getDiscordUser', this.uid)
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {String} 限定グループのURL
     */
    groupURL () {
      return this.$store.getters['settingGroup/groupURL']
    },
    /**
     * @return {Boolean} Discord連携しているかどうか
     */
    isDiscordLinkage () {
      return this.$store.getters['settingGroup/discordLinkage']
    },
    /**
     * @return {Boolean} Discordサーバーに参加済みかどうか
     */
    isJoinedDiscordServer () {
      return this.$store.getters['discordUsers/isJoined']
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.home {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__title {
    margin: 0;
    font-size: 1.2rem;
    color: $black_lighten_color;
    text-align: center;
  }
  &__menu {
    margin-top: 20px;
  }
}
</style>
