import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 課金予定情報
    subscriptionSchedule: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} サブスクリプションスケジュール
   */
  subscriptionSchedule: state => state.subscriptionSchedule
}

const mutations = {
  /**
   * 最新のサブスクリプションスケジュールをセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} subscriptionSchedule サブスクリプションスケジュール
   */
  setSubscriptionSchedule: (state, subscriptionSchedule) => {
    state.subscriptionSchedule = subscriptionSchedule
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 最新のサブスクリプションスケジュールを取得する
   * @param {String} uid ユーザID
   */
  async getSubscriptionSchedule ({ commit }, uid) {
    try {
      const snapshot = await firestore.collection('payments')
        .doc(uid)
        .collection('subscription_schedule')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()
      const doc = snapshot.docs[0]
      if (doc) commit('setSubscriptionSchedule', Object.assign(doc.data(), { ssid: doc.id }) )
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
