import { firebase, provider } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    uid: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ユーザーID
   */
  uid: state => state.uid
}

const mutations = {
  /**
    * ユーザーIDをstateにセット
    * @param {Object} state 暗黙的に受け取るstate
    * @param {String} uid ユーザーID
    */
  setUID: (state, uid) => {
    state.uid = uid
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
    * ユーザ情報が更新時、uidを更新する
    */
  onAuth ({ commit, getters, rootGetters, dispatch }) {
    const unsubscribe = firebase.auth().onAuthStateChanged(async auth => {
      commit('setAuthProcessing', true, { root: true })

      // auth情報をセット
      commit('setUID', auth ? auth.uid : null)

      // 遷移用のパラメータセット
      const query = { auth: new Date().getTime() }

      // auth有無関係なく必要な情報
      await Promise.all([
        dispatch('settingGeneral/getSettingGeneral', 'companyURL', { root: true }),
        dispatch('settingImages/getSettingImage', 'logo', { root: true }),
        dispatch('settingGeneral/getSettingGeneral', 'serviceName', { root: true }),
        dispatch('settingGeneral/getSettingGeneral', 'contact', { root: true }),
        dispatch('settingFooters/getSettingFooters', null, { root: true })
      ])

      if (auth) {
        // ユーザー、例外、カード情報を取得
        const promises = []

        promises.push(dispatch('users/getUser', auth.uid, { root: true }))
        promises.push(dispatch('exemptions/getExemption', auth.uid, { root: true }))
        promises.push(dispatch('cards/getCard', auth.uid, { root: true }))
        promises.push(dispatch('settingDisplayed/getSettingDisplayed', null, { root: true }))

        const [user, exemption, card] = await Promise.all(promises)

        // アカウント作成で必要な情報の取得
        const isSignupUser = sessionStorage.getItem('isSignupUser')
        const isSignupCard = user && !user.name && (!exemption && !card)

        // 初回サインアップの場合
        if (isSignupUser) {
          const invitationCode = sessionStorage.getItem('invitationCode')
          // 例外情報の取得
          const newExemption = JSON.parse(sessionStorage.getItem('exemption'))
          // 例外ユーザーがクエリ無しURLから飛んでいないかを確認するため、
          // newExemptionに例外情報が無い場合は、auth.emailから例外情報を取得
          if (!newExemption) await dispatch('exemptions/getExemptionByEmail', auth.email, { root: true })

          if (user) {
            // 既にユーザー情報がある場合はログアウト
            await dispatch('signout')
            commit('setTelop', { show: true, msg: 'アカウントが存在します\nログインしてください', type: 'warning' }, { root: true })
            commit('setRedirectURL', '/', { root: true })
          } else if (newExemption && (newExemption.email !== auth.email)) {
            // 例外情報があり、ログイン時のメールアドレスと一致しない場合
            await dispatch('signout')
            // 例外情報をstoreにセットしなおす
            commit('exemptions/setExemption', newExemption, { root: true })
            commit('setTelop', { show: true, msg: 'ログインに用いたメールアドレスが異なります\n別のメールアドレスに変更してください', type: 'warning' }, { root: true })
            commit('setRedirectURL', '/signup', { root: true })
            query.email = newExemption.email
          } else if (!newExemption && rootGetters['exemptions/exemption']) {
            // Firebase上に例外として登録されているがクエリ付きのURLから飛んでいないため、
            // sessionStorageに例外情報(newExemption)がストアされていない場合
            await dispatch('signout')
            commit('setTelop', { show: true, msg: '無料枠で招待されています\n招待メールをご確認ください', type: 'warning' }, { root: true })
            commit('setRedirectURL', '/signup', { root: true })
          } else {
            await dispatch('signup', { uid: auth.uid, exemption: newExemption, invitationCode: invitationCode })
            commit('setRedirectURL', '/signup/user', { root: true })
          }

          // セッション情報の削除
          const sessionItemKeys = ['isSignupUser', 'exemption', 'invitationCode']
          sessionItemKeys.forEach(key => sessionStorage.removeItem(key))
        } else if (isSignupCard) {
          // 通常ユーザーかつ支払情報が未登録のためカード登録画面へ遷移する
          commit('setRedirectURL', '/signup/card', { root: true })
        } else {
          // 通常のアクセスの場合
          if (!user) {
            // ユーザー情報がない場合ログアウト
            await dispatch('signout')
            commit('setTelop', { show: true, msg: 'アカウントが存在しません\n別のアカウントでログインするか入会申し込みをお願いします', type: 'warning' }, { root: true })
            commit('setRedirectURL', '/', { root: true })
          } else if (user.isDeleted) {
            // 退会済みかつFBグループ退会処理前だとauth情報が残っておりログインできてしまうのでさせるログアウト
            await dispatch('signout')
            commit('setTelop', { show: true, msg: 'このアカウントは退会済みです。', type: 'error' }, { root: true })
            commit('setRedirectURL', '/', { root: true })
          } else {
            // ログインの場合テロップを表示してホームへ遷移する
            const result = await firebase.auth().getRedirectResult()
            if (result.user) {
              commit('setTelop', { show: true, msg: 'ログインしました', type: 'success' }, { root: true })
              commit('setRedirectURL', '/home', { root: true })
            }
          }
        }

        // ログアウトしていなければ初期処理を行う
        if (firebase.auth().currentUser) {
          // 支払い情報とサブスクリプション情報の監視を開始
          dispatch('payments/onPayment', auth.uid, { root: true })
          dispatch('subscriptions/onSubscription', auth.uid, { root: true })

          // 全体で利用するデータの取得
          const firstPromises = []
          firstPromises.push(dispatch('tickets/getTickets', auth.uid, { root: true }))

          await Promise.all(firstPromises)
        }
      }
      const redirectPath = rootGetters.redirectPath
      const queryParameter = rootGetters.queryParameter

      // 元々あったパラメータを消さないように再セット
      queryParameter.substr(1).split('&').forEach(params => {
        const param = params.split('=')
        if (param[0] && param[0] !== 'auth') query[param[0]] = decodeURIComponent(param[1])
      })

      commit('setAuthProcessing', false, { root: true })
      router.replace({ path: redirectPath, query: query })
      unsubscribe()
    })
  },
  /**
   * サインアップ周りの処理
   * @param {Object} payload サインアップに使う情報
   * @param {String} payload.uid サインアップするユーザーのID
   * @param {Object} payload.exemption 例外情報 存在しない場合はnull
   * @param {String} payload.invitationCode 招待コード 存在しない場合はnull
   */
  signup: async ({ commit, dispatch }, payload) => {
    try {
      const promises = []

      promises.push(dispatch('users/setUser', { uid: payload.uid, params: {} }, { root: true }))

      // 例外情報があればuidの更新を行う
      if (payload.exemption) {
        const newExemption = {
          uid: payload.uid,
          email: payload.exemption.email,
          registratedAt: new Date(),
          updatedAt: new Date()
        }
        promises.push(dispatch('exemptions/updateExemption', { eid: payload.exemption.eid, params: newExemption }, { root: true }))
      }

      if (payload.invitationCode) {
        promises.push(dispatch('invitationHistories/addHistory', { uid: payload.uid, code: payload.invitationCode, createdAt: new Date() }, { root: true }))
      }

      // ユーザー情報の作成とあれば例外情報にuidを登録
      await Promise.all(promises)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * サインイン
   * @param {String} sns SNS認証のプロバイダー名 google, facebook, twitter
   */
  signin: ({ commit }, sns) => {
    try {
      firebase.auth().signInWithRedirect(provider[sns])
      return
    } catch (error) {
      // エラーの場合はエラー画面に遷移させる
      router.push({ name: 'error' })
    }
  },
  /**
   * サインアウト
   * @return {Object} status：成功の有無（success or error）、error：エラーコード（エラー時のみ）
   */
  signout: async ({ commit, dispatch }) => {
    try {
      await firebase.auth().signOut()

      // storeの不要な情報をリセットする
      dispatch('resetState', null, { root: true })

      return { status: 'success' }
    } catch (error) {
      return { status: 'error', error: error.code }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
