<template>
  <v-app id="app" class="sbsc">
    <original-header v-if="!isAuthProcessing" />
    <router-view class="sbsc__main" v-if="!isAuthProcessing" v-show="!isSubmitting" />
    <original-footer class="sbsc__footer" v-if="!isAuthProcessing" />
    <processing v-if="isProcessing || isSubmitting" />
    <telop v-if="telop.show" />
  </v-app>
</template>

<script>
import OriginalHeader from '@/components/common/header.vue'
import OriginalFooter from '@/components/common/footer.vue'
import Processing from '@/components/common/processing.vue'
import Telop from '@/components/common/telop'

export default {
  components: { OriginalHeader, OriginalFooter, Processing, Telop },
  async mounted () {
    // authの監視
    this.$store.dispatch('auth/onAuth')

    // メンテナンス情報の監視を開始
    this.$store.dispatch('maintenances/onMaintenance')

    // 停止情報の監視を開始
    this.$store.dispatch('stops/onStop')
  },
  computed: {
    /**
     * @return {Boolean} 初期処理中かどうか
     */
    isAuthProcessing () {
      return this.$store.getters.isAuthProcessing
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Boolean} フォーム等で情報を更新中かどうか
     */
    isSubmitting () {
      return this.$store.getters.isSubmitting
    },
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters.telop
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

html {
  font-size: 10px !important;
}

#app {
  font-family: "Noto Sans JP", Avenir, Helvetica, Arial, sans-serif;
  color: $black_lighten_color;
  background-color: $white_color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $black_lighten_color !important;
  text-decoration: none;
  outline: none;
}

.textlink {
  color: $green_color !important;
  text-decoration: underline !important;
}

.sbsc {
  width: 100vw;
  max-width: $max_width;
  margin: 0 auto;
  & > .v-application--wrap {
    display: flex;
    flex-flow: row, wrap;
    min-height: 100vh;
    text-align: justify;
  }
  &__main {
    width: 100%;
  }
  &__footer {
    align-self: flex-end;
  }
}
</style>
