import Vue from 'vue'
import VueSanitize from 'vue-sanitize'
import VueClipboard from 'vue-clipboard2'
import VueGtag from 'vue-gtag'
import VueGtm from '@gtm-support/vue2-gtm'

import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

import '@babel/polyfill'
import 'whatwg-fetch'

/**
 * setting of vue sanitize
 * @see https://github.com/daichirata/vue-sanitize
 */
Vue.use(VueSanitize)

/**
 * @see https://github.com/Inndy/vue-clipboard2
 */
Vue.use(VueClipboard)

/**
 * setting of gtm
 * @see https://github.com/gtm-support/vue-gtm/tree/vue2-gtm
 */
const gTMIDs = process.env.VUE_APP_GTM_ID ? process.env.VUE_APP_GTM_ID.split(',') : []
const enableGTM = gTMIDs.length > 0
if (enableGTM) {
  Vue.use(VueGtm, { id: gTMIDs.length > 1 ? gTMIDs : gTMIDs[0] })
}

/**
 * setting of gtag GTM優先とする
 * @see https://matteo-gabriele.gitbook.io/vue-gtag
 */
const gtagIDs = process.env.VUE_APP_GTAG_ID ? process.env.VUE_APP_GTAG_ID.split(',') : []
if (!enableGTM && gtagIDs.length > 0) {
  const options = { config: { id: gtagIDs[0] } }
  if (gtagIDs.length > 1) {
    const includes = []
    gtagIDs.slice(1).forEach(id => {
      includes.push({ id: id })
    })
    options.includes = includes
  }
  Vue.use(VueGtag, options, router)
}

Vue.config.productionTip = false
Vue.config.devtools = !(process.env.NODE_ENV === 'production')

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
