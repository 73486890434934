import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    card: null,
    unsubscribe: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} カード情報
   */
  card: state => state.card,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} リスナーのデタッチ情報
   */
  unsubscribe: state => state.unsubscribe
}

const mutations = {
  /**
   * カード情報のセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} card カード情報
   */
  setCard: (state, card) => {
    state.card = card
  },
  /**
   * カード情報監視の際のunsubscribeのセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} unsubscribe リスナーのデタッチ情報
   */
  setUnsubscribe: (state, unsubscribe) => {
    state.unsubscribe = unsubscribe
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 新しいカード情報の監視
   * @param {String} uid ユーザーID
   * @return {void} 新しくカードが作成されたらreturn
   */
  onNewCard ({ commit }, uid) {
    return new Promise(async resolve => {
      let isInitial = true
      const unsubscribe = await firestore.collection('payments').doc(uid).collection('cards').onSnapshot(querySnapshot => {
        // カード情報の更新にかかわらず初回は動くので、初回は無視する
        if (isInitial) {
          isInitial = false
          return
        }

        for (const change of querySnapshot.docChanges()) {
          // 変更のうち新規追加があったら返却
          if (change.type === 'added') {
            commit('setCard', change.doc.data())
            return resolve()
          }
        }
      }, () => {
        router.push({ name: 'error' })
      })

      // リスナーのデタッチ情報の格納
      commit('setUnsubscribe', unsubscribe)
    })
  },
  /**
   * 新しいカード情報の監視の解除
   */
  unsubscribeOnNewCard ({ commit, getters }) {
    // リスナーが登録されていたらデタッチ
    if (getters.unsubscribe) getters.unsubscribe()
  },
  /**
   * カード情報の取得
   * @param {String} uid ユーザーID
   */
  getCard: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('payments')
        .doc(uid)
        .collection('cards')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()
      let card = null
      snapshot.forEach(doc => {
        card = doc.data()
        commit('setCard', card)
      })
      return card
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
