import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // FAQの一覧
    faqs: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} FAQの一覧
   */
  faqs: state => state.faqs
}

const mutations = {
  /**
   * FAQの一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} faqs FAQの一覧
   */
  setFaqs: (state, faqs) => {
    state.faqs = faqs
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * FAQの一覧を取得
   */
  getFaqs: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('faqs')
        .orderBy('rank', 'asc')
        .get()
      const faqs = []
      snapshot.forEach(doc => {
        // faqidも各FAQに割り当てる
        faqs.push(Object.assign(doc.data(), { faqid: doc.id }))
      })
      commit('setFaqs', faqs)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
