import { firestore } from '@/firebase'
import router from '@/router'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * 利用した招待コードを履歴に追加する
   * @param {Object} params 履歴情報
   * @param {String} params.uid 利用したユーザID
   * @param {String} params.code 利用した招待コード
   * @param {Date} params.createdAt 利用した日時 (厳密には利用完了した日時)
   */
  async addHistory ({ commit }, params) {
    try {
      await firestore.collection('invitation_histories').add(params)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
