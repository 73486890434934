import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    subscription: null,
    unsubscribe: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} サブスクリプション情報
   */
  subscription: state => state.subscription,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} リスナーのデタッチ情報
   */
  unsubscribe: state => state.unsubscribe
}

const mutations = {
  /**
   * サブスクリプション情報のセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} subscription サブスクリプション情報
   */
  setSubscription: (state, subscription) => {
    state.subscription = subscription
  },
  /**
   * サブスクリプション情報監視の際のunsubscribeのセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} unsubscribe リスナーのデタッチ情報
   */
  setUnsubscribe: (state, unsubscribe) => {
    state.unsubscribe = unsubscribe
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * サブスクリプション情報の監視
   * @param {String} uid ユーザーID
   */
  onSubscription ({ commit, dispatch, state }, uid) {
    firestore.collection('payments').doc(uid).collection('subscriptions')
      .orderBy('completedAt', 'desc').limit(1).onSnapshot(querySnapshot => {
        // 新規追加に対してのみ処理を実行
        let subscription = null
        for (const change of querySnapshot.docChanges()) {
          if (change.type === 'added') subscription = change.doc.data()
        }

        // 新しいサブスクリプションの格納
        commit('setSubscription', subscription)

        // 新規追加がない場合は処理終了
        if (!subscription) return

        // サブスクリプションのステータスによって処理を分岐
        if (['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(subscription.status)) {
          // 支払いに失敗しているのでカード変更画面に遷移
          commit('setTelop', { show: true, msg: '決済処理に失敗しました。カード情報を変更してください。', type: 'error' }, { root: true })
          router.push({ name: 'payment_card_change' })

          // onAtuhの遷移より前に終わる可能性もあるのでリダイレクト先を更新
          commit('setRedirectURL', '/payment/card/change', { root: true })
        }
      }, error => {
        // ログアウトするとrulesで権限エラーが発生し、自動でリスナーをデタッチする仕様となっている
        // それ以外のエラーが出た場合はエラー画面に飛ばす
        if (error.code !== 'permission-denied') {
          router.push({ name: 'error' })
        }
      })
  },
  /**
   * 決済失敗時のカード更新の際の決済結果確認のためのサブスクリプション監視リスナーを起動する
   * @param {String} uid ユーザーID
   * @return {void} 新しくサブスクリプションが作成されたらreturn
   */
  onNewSubscription ({ commit }, uid) {
    return new Promise(async resolve => {
      let isInitial = true
      const unsubscribe = firestore.collection('payments').doc(uid).collection('subscriptions')
        .orderBy('completedAt', 'desc').limit(1).onSnapshot(querySnapshot => {
        // サブスクリプション情報の更新にかかわらず初回は動くので、初回は無視する
          if (isInitial) {
            isInitial = false
            return
          }

          for (const change of querySnapshot.docChanges()) {
          // 変更のうち新規追加があったら返却
            if (change.type === 'added') {
              return resolve()
            }
          }
        }, () => {
          router.push({ name: 'error' })
        })

      // リスナーのデタッチ情報の格納
      commit('setUnsubscribe', unsubscribe)
    })
  },
  /**
   * 新しいサブスクリプション情報の監視の解除
   */
  unsubscribeOnNewSubscription ({ commit, getters }) {
    // リスナーが登録されていたらデタッチ
    if (getters.unsubscribe) getters.unsubscribe()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
