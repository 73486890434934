<template>
  <footer class="footer" v-if="isDisplay">
    <v-img class="footer__logo" v-if="logoImage" :src="logoImage.icon" contain />
    <p class="footer__text" v-else>{{ serviceName }}</p>
    <div class="footer__links">
      <!-- オリジナルフッター -->
      <div class="footer__links__item" v-for="(footerInfo, index) in  displayedFooterInfo" :key="footerInfo.name + index">
        <v-icon class="footer__links__item__icon">arrow_forward_ios</v-icon>
        <!-- フッターのリンクはaタグとrouterの2パターンに分かれる -->
        <a class="footer__links__item__text" target="_blank" rel="noopener noreferrer"
           :href="footerInfo.url" v-if="!footerInfo.to">{{ footerInfo.name }}</a>
        <router-link class="footer__links__item__text" :to="footerInfo.to" v-else>{{ footerInfo.name }}</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import url from '@/assets/lib/url'
// フッターを表示させるページ名
const DISPLAY = [
  'top',
  'signup',
  'signup_invitation',
  'signup_card',
  'signup_user'
]

export default {
  mixins: [url],
  computed: {
    /**
     * @return {Boolean} フッターを表示させるかどうか
     */
    isDisplay () {
      return DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {String} 運営元のURL
     */
    companyURL () {
      return this.$store.getters['settingGeneral/settingGeneral']('companyURL').value
    },
    /**
     * @return {Object} ロゴの画像
     */
    logoImage () {
      return this.$store.getters['settingImages/settingImage']('logo')
    },
    /**
     * @return {String} サービス名
     */
    serviceName () {
      return this.$store.getters['settingGeneral/settingGeneral']('serviceName').value
    },
    /**
     * @return {Object} 登録済みのオリジナルフッター情報
     */
    settingFooter () {
      return this.$store.getters['settingFooters/settingFooters']
    },
    /**
     * @return {String} お問い合わせ先
     */
    contact () {
      return this.$store.getters['settingGeneral/settingGeneral']('contact').value
    },
    /**
     * @return {Boolean} お問い合わせ先がURLかどうか
     */
    isContactURL () {
      return this.isValidURL(this.contact)
    },
    /**
     * @return {Object[]} 表示するフッター情報
     */
    displayedFooterInfo () {
      // url：外部リンク、to：内部リンク
      const footerInfo = [
        { name: '運営', url: this.companyURL, to: null },
        { name: 'お問い合わせ', url: this.isValidURL(this.contact) ? this.contact : 'mailto:' + this.contact + '?subject=' + this.serviceName + 'に関するお問い合わせ', to: null },
        { name: '利用規約', url: null, to: { name: 'terms' } },
        { name: 'プライバシーポリシー', url: null, to: { name: 'policy' } },
        { name: '特定商取引法に基づく表記', url: null, to: { name: 'law' } }
      ]

      // 設定情報があれば更新
      if (this.settingFooter.length) {
        // 設定されたフッター情報の運営名に変更する
        footerInfo[0].name = this.settingFooter[0].name
        // 運営の下にオリジナルフッター情報を挿入する
        Array.prototype.splice.apply(footerInfo, [1, 0].concat(this.settingFooter.slice(1)))
      }
      return footerInfo
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.footer {
  z-index: $footer_zindex;
  width: 100vw;
  max-width: $max_width;
  min-height: $footer_height;
  padding: 10px $padding_width 15px;
  margin: 100px auto 0;
  background-color: $gray_lighten_color;
  &__logo {
    height: 30px;
    margin: 0 auto 7px;
  }
  &__text {
    font-size: 1.2rem;
    text-align: center;
  }
  &__links {
    display: flex;
    flex-wrap: wrap;
    max-width: 321px;
    margin: 0 auto;
    &__item {
      display: flex;
      align-items: center;
      width: 50%;
      margin-top: 10px;
      &__icon {
        width: 10px;
        height: 10px;
        &.v-icon {
          font-size: 1rem;
        }
      }
      &__text {
        padding-right: 10px;
        padding-left: 5px;
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
}
</style>
