import { firestore } from '@/firebase'
import router from '@/router'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * トークン情報の作成
   * 既に存在する場合は上書き保存される
   * @param {Object} payload 引数
   * @param {String} payload.uid ユーザーID
   * @param {String} payload.tokenID stripe.createTokenから取得されるトークン
   */
  createToken: async ({ commit }, payload) => {
    try {
      await firestore.collection('payments')
        .doc(payload.uid)
        .collection('tokens')
        .add({
          tokenID: payload.tokenID,
          createdAt: new Date()
        })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
