import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // フッター情報の一覧
    settingFooters: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} フッター情報の一覧
   */
  settingFooters: state => state.settingFooters
}

const mutations = {
  /**
   * フッター情報の一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} footerInfos フッター情報
   */
  setSettingFooters: (state, footerInfos) => {
    state.settingFooters = footerInfos
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * フッター情報の一覧を取得
   */
  getSettingFooters: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_footer')
        .orderBy('rank', 'asc')
        .get()
      const footerInfos = []
      snapshot.forEach(doc => {
        // sfidも各フッター情報に割り当てる
        footerInfos.push(Object.assign(doc.data(), { sfid: doc.id }))
      })
      commit('setSettingFooters', footerInfos)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
