import { firestore, convertDateToTimestamp } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 有効期限内の回数券情報 (終了日時が近い順)
    tickets: null,
    // 利用済みの回数券情報（最新の利用日順）
    usedHistories: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 有効期限内の回数券情報 (終了日時が近い順に並んでいる)
   */
  tickets: state => state.tickets,
  /**
   * 利用可能な回数券の一覧を取得する (終了日時が近い順に並んでいる)
   * 利用可能: 利用日時が登録されていないかつ取得日が利用開始日以降である.
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 利用可能な回数券一覧
   */
  availableTickets: state => {
    if (!state.tickets || state.tickets.length === 0) {
      return []
    }
    const nowAt = new Date()
    const tiids = Object.keys(state.tickets)
    return tiids.flatMap(tiid => !state.tickets[tiid].usedAt && nowAt > state.tickets[tiid].startAt.toDate() ? state.tickets[tiid] : [])
  },
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 利用済みの回数券情報のセット
   */
  usedHistories: state => state.usedHistories,
  /**
   * 最新の利用済み回数券情報を取得する
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新の利用済み回数券情報 or null(利用履歴がないとき)
   */
  latestUsedTicket: state => state.usedHistories.length > 0 ? state.usedHistories[0] : null
}

const mutations = {
  /**
   * 有効期限内の回数券情報のセット (終了日時が近い順に並んでいる)
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} tiid 回数券のドキュメントID
   * @param {Object} ticket 回数券のオブジェクト情報
   */
  setTicket: (state, { tiid, ticket }) => {
    if (!state.tickets) state.tickets = {}
    state.tickets[tiid] = ticket
  },
  /**
   * 回数券情報の更新 変更検知のためObjectの参照を入れ替える
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} tiid 回数券のドキュメントID
   * @param {Object} ticket 回数券のオブジェクト情報
   */
  updateTicket: (state, { tiid, ticket }) => {
    state.tickets[tiid] = ticket
    state.tickets = Object.assign({}, state.tickets)
  },
  /**
   * 利用した回数券情報のセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} ticket 利用済み回数券のオブジェクト情報
   */
  setUsedTicket: (state, ticket) => {
    state.usedHistories.push(ticket)
  },
  /**
   * 利用された最新の回数券情報をセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} ticket 利用済み回数券のオブジェクト情報
   */
  setLatestUsedTicket: (state, ticket) => {
    state.usedHistories.unshift(ticket)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * 有効期限内の回数券を取得する
   * @param {String} uid ユーザーID
   */
  getTickets: async ({ commit }, uid) => {
    try {
      // 今日の日付
      const nowAt = new Date()

      // 利用終了日時が取得した日時以降のチケットを順番に取得する
      const snapshot = await firestore
        .collection('tickets')
        .where('uid', '==', uid)
        .orderBy('endAt')
        .startAt(nowAt)
        .get()

      snapshot.forEach(doc => {
        const ticket = Object.assign(doc.data(), { tiid: doc.id })
        commit('setTicket', { tiid: doc.id, ticket: ticket })
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 利用済みの回数券情報の取得
   * @param {String} uid ユーザーID
   */
  getUsedTicketHistories: async ({ commit }, uid) => {
    try {
      /**
       * @todo where句が必要なのか調査する
       */
      const snapshot = await firestore
        .collection('tickets')
        .where('uid', '==', uid)
        .where('usedAt', '!=', null)
        .orderBy('usedAt', 'desc')
        .get()

      snapshot.forEach(doc => {
        const ticket = Object.assign(doc.data(), { tiid: doc.id })
        commit('setUsedTicket', ticket)
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 回数券の利用
   * @param {String} tiid 回数券のドキュメントID
   */
  usedTicket: async ({ commit, getters }, tiid) => {
    try {
      await firestore
        .collection('tickets')
        .doc(tiid)
        .update({ usedAt: new Date() })

      const ticket = getters.tickets[tiid]
      ticket.usedAt = convertDateToTimestamp(new Date())
      commit('updateTicket', { tiid: tiid, ticket: ticket })
      commit('setLatestUsedTicket', ticket)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
