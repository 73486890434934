import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 例外情報の取得済みかどうか
    // 自分自身の例外情報は一度取得すれば十分なので、変数として取得済みの有無を持つ
    isGot: false,
    // 自分自身の例外情報
    exemption: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 取得済みかどうか
   */
  isGot: state => state.isGot,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 自分自身の例外情報（ない場合はnull）
   */
  exemption: state => state.exemption
}

const mutations = {
  /**
   * 例外情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} exemption 例外情報（ない場合はnull）
   */
  setExemption: (state, exemption) => {
    state.exemption = exemption
    state.isGot = true
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定uidの例外情報を取得
   * @param {String} uid ユーザーID
   */
  getExemption: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('exemptions')
        .where('uid', '==', uid)
        .get()
      const exemption = snapshot.size === 1 ? Object.assign(snapshot.docs[0].data(), { eid: snapshot.docs[0].id }) : null
      commit('setExemption', exemption)
      return exemption
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定メールアドレスを持つ未使用の例外情報を取得
   * @param {String} email 例外情報に紐づくメールアドレス
   */
  getExemptionByEmail: async ({ commit }, email) => {
    try {
      const snapshot = await firestore
        .collection('exemptions')
        .where('email', '==', email)
        .get()
      const exemption = snapshot.size === 1 ? Object.assign(snapshot.docs[0].data(), { eid: snapshot.docs[0].id }) : null
      commit('setExemption', exemption)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 例外情報の更新
   * @param {Object} payload 例外情報
   * @param {String} payload.eid 更新する例外情報のID
   * @param {Object} payload.params 更新情報
   */
  updateExemption: async ({ commit }, payload) => {
    try {
      await firestore.collection('exemptions')
        .doc(payload.eid)
        .update(payload.params)
      commit('setExemption', payload.params)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 例外情報の削除
   * @param {String} eid 例外情報のID
   */
  deleteExemption: async ({ commit }, eid) => {
    try {
      await firestore
        .collection('exemptions')
        .doc(eid)
        .delete()
      commit('setExemption', null)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
