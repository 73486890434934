import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 特典情報の一覧
    settingPrivileges: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 特典情報の一覧
   */
  settingPrivileges: state => state.settingPrivileges
}

const mutations = {
  /**
   * 特典情報の一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} settingPrivileges 特典情報の一覧
   */
  setSettingPrivileges: (state, settingPrivileges) => {
    state.settingPrivileges = settingPrivileges
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 特典情報の一覧を取得
   */
  getSettingPrivileges: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_privileges')
        .orderBy('rank', 'asc')
        .get()
      const settingPrivileges = []
      snapshot.forEach(doc => {
        settingPrivileges.push(doc.data())
      })
      commit('setSettingPrivileges', settingPrivileges)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
