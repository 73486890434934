import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 代表者情報の設定内容一覧
    // { type: {}, type: {}, ... }
    settingRepresentatives: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type 代表者情報の設定内容の種類
   * @return {Object} 代表者情報の設定内容
   */
  settingRepresentative: state => type => state.settingRepresentatives[type] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 代表者情報の設定内容全て
   */
  settingRepresentativeAll: state => state.settingRepresentatives
}

const mutations = {
  /**
   * 代表者情報の設定内容をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.type 代表者情報の設定内容の種類
   * @param {Object} payload.settingRepresentative 代表者情報の設定内容
   */
  setSettingRepresentative: (state, payload) => {
    state.settingRepresentatives = Object.assign({}, state.settingRepresentatives, { [payload.type]: payload.settingRepresentative })
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 代表者情報の設定内容を取得
   * @param {String} type 代表者情報の設定内容の種類
   */
  getSettingRepresentative: async ({ commit }, type) => {
    try {
      const snapshot = await firestore
        .collection('setting_representatives')
        .where('type', '==', type)
        .get()
      const doc = snapshot.docs[0]
      if (doc) commit('setSettingRepresentative', { type: type, settingRepresentative: Object.assign(doc.data(), { srid: doc.id }) })
    } catch {
      router.push({ name: 'error' })
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
