import { firestore } from '@/firebase'
import router from '@/router'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * 退会情報の作成
   * @param {Object} payload 引数
   * @param {String} payload.uid ユーザーID
   * @param {String} payload.params 退会情報
   */
  setCancel: async ({ commit }, payload) => {
    try {
      await firestore.collection('cancels')
        .doc(payload.uid)
        .set(payload.params)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
