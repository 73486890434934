import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // ドキュメントID
    siid: null,
    // 招待機能を利用するか
    used: false,
    // 入会できるユーザを招待限定とするか
    limited: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 招待機能設定情報が存在するか
   */
  existSetting: state => Boolean(state.siid),
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 招待機能を利用しているかどうか
   */
  used: state => state.used,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 招待機能を利用し入会ユーザを招待限定とするか
   */
  limited: state => state.used && state.limited
}

const mutations = {
  /**
   * 招待機能設定情報をセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload セットされる引数の情報
   * @param {String} payload.siid ドキュメントID
   * @param {Boolean} payload.used 招待機能をできるか
   * @param {Boolean} payload.limited 入会できるユーザを招待限定とするか
   */
  setInvitationSetting: (state, payload) => {
    state = Object.assign(state, payload)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 招待機能設定情報を取得する
   */
  getInvitationSetting: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_invitation')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      const doc =snapshot.empty ? null : snapshot.docs[0]
      if (doc) commit('setInvitationSetting', Object.assign({}, { siid: doc.id, used: doc.data().used, limited: doc.data().limited }))
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
