import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 利用可能な回数券の設定情報
    settingTicket: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 利用可能な回数券の設定情報
   */
  settingTicket: state => state.settingTicket
}

const mutations = {
  /**
   * 利用可能な回数券の設定情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} settingTicket 設定情報
   */
  setSettingTicket: (state, settingTicket) => {
    state.settingTicket = settingTicket
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 回数券の設定情報を取得
   * @param {Sring} stid ドキュメントID
   */
  getSettingTicket: async ({ commit }, stid) => {
    try {
      const doc = await firestore
        .collection('setting_tickets')
        .doc(stid)
        .get()

      if (doc.exists) commit('setSettingTicket', Object.assign({ stid: stid }, doc.data()))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 現在稼働中の回数券情報を取得
   */
  getActivateSettingTicket: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_tickets')
        .orderBy('createdAt', 'desc')
        .get()

      const settingTickets = snapshot.docs.flatMap(doc => !doc.data().endAt || (doc.data().startAt.toDate() <= new Date() && doc.data().endAt.toDate() > new Date()) ? Object.assign({ stid: doc.id }, doc.data()) : [])

      // 取得できなかったときには利用可能な回数券情報はないと判断しnullを設定する
      commit('setSettingTicket', settingTickets.length ? Object.assign({ stid: settingTickets[0].stid }, settingTickets[0]) : null)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
