import { firebase } from '@/firebase'
import router from '@/router'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * 画像のアップロード
   * @param {Object} payload 引数の情報
   * @param {Blob} payload.blob アップロードする画像のBlob
   * @param {String} payload.path アップロードする画像の格納先
   * @return {Object} url: アップロード後のURL
   */
  uploadImage: async ({ commit }, payload) => {
    try {
      const ref = firebase
        .storage()
        .ref()
        .child(payload.path)

      // メタデータ
      const metadata = {
        cacheControl: 'public,max-age=31536000',
        contentType: 'image/' + payload.path.match(/(.*)(?:\.([^.]+$))/)[2]
      }

      await ref.put(payload.blob, metadata)
      const url = await ref.getDownloadURL()

      return url
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 画像の削除
   * @param {String} url 削除したい画像のURL
   */
  deleteImage: async ({ commit }, url) => {
    try {
      await firebase
        .storage()
        .refFromURL(url)
        .delete()
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
