import { firebase } from '@/firebase'
import router from '@/router'

// functionsがあるリージョン
const REGION = 'asia-northeast1'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * イベントに対応したメール送信
   * @param {String} event イベント名
   * @see https://docs.google.com/spreadsheets/d/1VkLTGBv4xDddTbCn3vQ2SU3qQ7cLiE1tkAnfMVSmq7Y/edit#gid=0
   */
  sendEventEmail: async ({ commit }, event) => {
    try {
      const sendEventEmail = firebase.functions(REGION).httpsCallable('sendEventEmail')
      await sendEventEmail({ event: event })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * Stripeのカスタマー情報を削除
   */
  async deleteCustomer ({ commit }) {
    try {
      const deleteCustomer = firebase.functions(REGION).httpsCallable('deleteCustomer')
      deleteCustomer()
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 招待コードの存在有無をチェックする
   * @param {String} invitationCode 招待コードの文字列
   * @return {Object} 存在有無
   */
  async checkCode ({ commit }, invitationCode) {
    try {
      const checkCode = firebase.functions(REGION).httpsCallable('checkCode')
      const response = await checkCode({ ic: invitationCode })
      return response.data
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ログインユーザーをDiscordサーバーに参加させる
   * @param {String} code 認証コード
   */
  async joinedDiscord ({ commit }, code) {
    const joinedDiscord = firebase.functions(REGION).httpsCallable('joinedDiscord')
    await joinedDiscord({ code: code })
  },
  /**
   * 全ての登録（ユーザー情報、クレカ情報）が終わっているユーザーのみを取り出す
   */
  getActiveUIDs: async ({ commit }) => {
    try {
      const getActiveUIDs = firebase.functions(REGION).httpsCallable('getActiveUIDs')
      const result = await getActiveUIDs()
      commit('setActiveUIDs', result.data, { root: true })
    } catch {
      router.push({ name: 'error' })
    }
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
