import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/group',
      name: 'group',
      component: loadComponent('Group.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/member',
      name: 'member',
      component: loadComponent('Member.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ticket',
      name: 'ticket',
      component: loadComponent('Ticket.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ticket/available',
      name: 'ticket_available',
      component: loadComponent('TicketAvailable.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ticket/histories',
      name: 'ticket_histories',
      component: loadComponent('TicketHistories.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ticket/done',
      name: 'ticket_done',
      component: loadComponent('TicketDone.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/',
      name: 'top',
      component: loadComponent('Top.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      component: loadComponent('Signup.vue')
    },
    {
      path: '/signup/invitation',
      name: 'signup_invitation',
      component: loadComponent('SignupInvitation.vue')
    },
    {
      path: '/login/:provider',
      name: 'login',
      component: loadComponent('Login.vue')
    },
    {
      path: '/account/:provider',
      name: 'account',
      component: loadComponent('Account.vue')
    },
    {
      path: '/signup/user',
      name: 'signup_user',
      component: loadComponent('SignupUser.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/signup/card',
      name: 'signup_card',
      component: loadComponent('SignupCard.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/signup/group',
      name: 'signup_group',
      component: loadComponent('SignupGroup.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/discord',
      name: 'discord',
      component: loadComponent('Discord.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/signup/done',
      name: 'signup_done',
      component: loadComponent('SignupDone.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/edit',
      name: 'profile_edit',
      component: loadComponent('ProfileEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/:uid',
      name: 'profile',
      component: loadComponent('Profile.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/payment/plan',
      name: 'payment_plan',
      component: loadComponent('PaymentPlan.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/payment/card/change',
      name: 'payment_card_change',
      component: loadComponent('PaymentCardChange.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/menu',
      name: 'menu',
      component: loadComponent('Menu.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: loadComponent('Cancel.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/stop',
      name: 'stop',
      component: loadComponent('Stop.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: loadComponent('Maintenance.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/terms',
      name: 'terms',
      component: loadComponent('Terms.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/policy',
      name: 'policy',
      component: loadComponent('Policy.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/law',
      name: 'law',
      component: loadComponent('Law.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/faq',
      name: 'faq',
      component: loadComponent('Faq.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('NotFound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notsupported',
      name: 'notsupported',
      component: loadComponent('NotSupported.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // ブラウザバック or 戻るボタンを押したときは表示するページの描画後に前回の表示位置にスクロールさせる.
    // 上記以外のページ遷移時にはトップを表示させる
    return new Promise(resolve => {
      if (savedPosition) {
        const isProcessingWatch = store.watch(() => store.getters.isProcessing, (newValue, oldValue) => {
          if (newValue === false && oldValue === true) {
            // 処理中 => 完了になったとき監視をやめて前回の表示位置を返却する
            unwatch()
            resolve(savedPosition)
          }
        })
        const unwatch = () => isProcessingWatch()
      } else {
        resolve({ x: 0, y: 0 })
      }
    })
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const isNotSupportBrowser = to.name !== 'notsupported' && !store.getters.isSupportBrowser
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 初回アクセスのとき or サポート対象外のブラウザのときにチェックを行う
  if (isNotSupportBrowser) analysisBrowser(to, next)

  // 認証後の遷移先として現在のURLを保存する
  const redirectURL = store.getters.redirectPath
  if (!redirectURL) store.commit('setRedirectURL', to.path)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const user = uid ? store.getters['users/user'](uid) : null
  const exemption = uid ? store.getters['exemptions/exemption'] : null
  const card = store.getters['cards/card']
  const isAuthProcessing = store.getters.isAuthProcessing
  const payment = store.getters['payments/payment']
  const subscription = store.getters['subscriptions/subscription']

  // 遷移条件
  // ログイン済みかつユーザー情報があるが名前の登録がまだの場合ユーザー情報登録画面への遷移
  const isSignupUser = user && !user.name
  // ログイン済みかつユーザー情報が登録されており、例外ユーザーでなくカード情報が無い場合
  const isSignupCard = !exemption && !card
  // 決済が失敗している場合
  const isFailedPayment = (payment && payment.status === 'cardError') || (subscription && ['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(subscription.status))

  // LINEで外部ブラウザ遷移用パラメータがなければ付与した上で次の処理に飛ばす
  if (!to.query.openExternalBrowser) {
    next({ name: to.name, params: to.params, query: Object.assign({ openExternalBrowser: 1 }, to.query) })
  }

  // 認証、ユーザー取得状況に応じてページ遷移
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    if (autoTransition) {
      next()
    } else {
      // Auth処理中の場合はパラメータ情報が失われないようstoreに保存し遷移をキャンセルさせる
      store.commit('setQueryParameter', to.query ? `?${Object.keys(to.query).map(key => `${key}=${to.query[key]}`).join('&')}` : '')
      next(false)
    }
  } else if (!uid) {
    // 未ログインの場合
    requiresAuth ? next({ name: 'top', query: to.query }) : next()
  } else if (isSignupUser) {
    // ログイン済みかつユーザー情報未登録の場合
    to.name === 'signup_user' ? next() : next({ name: 'signup_user', query: to.query })
  } else if (isSignupCard) {
    // ログイン済みかつ通常ユーザーでまだカードが未登録の場合
    to.name === 'signup_card' ? next() : next({ name: 'signup_card', query: to.query })
  } else if (isFailedPayment) {
    // 決済が失敗している場合カード変更画面へ遷移させる
    to.name === 'payment_card_change' ? next() : next({ name: 'payment_card_change' })
  } else if (['signup_user', 'signup_card'].includes(to.name)) {
    // ログイン済みかつ加入済みで不要な画面に遷移しようとした場合ホームへ遷移させる
    next({ name: 'home', query: to.query })
  } else {
    // ログイン済みかつ加入済み
    requiresAuth ? next() : next({ name: 'home', query: to.query })
  }
})

/**
 * beforeEachで遷移が確定した後の処理
 */
router.beforeResolve((to, from, next) => {
  const isAuthProcessing = store.getters.isAuthProcessing

  // 遷移先と遷移元が異なればisProcessingをtrueにする
  if (!isAuthProcessing && to.name !== from.name) store.commit('setProcessing', true)
  next()
})

export default router

/**
 * ブラウザの解析をする
 * WebViewのときはOS毎に以下のように処理させる
 * iOS => サポート対象外ブラウザのページに遷移させる
 * android => 閲覧しようとしたページをchromeで開く
 * @see "https://docs.google.com/document/d/1WITn598mniIV7k2ZmBJT8wLO6vOhI41tXp8GC-Qihg0"
 *
 * @param {Object} to 遷移先ページ
 * @param {Object} next 次の処理
 */
const analysisBrowser = (to, next) => {
  const userAgent = parseUserAgent()
  store.commit('setSupportBrowser', userAgent.isSupportBrowser)

  if (userAgent.isIOSWebView) {
    store.commit('setReferrerPath', to.path)
    next({ name: 'notsupported' })
  } else if (userAgent.isAndroidWebView) {
    openAndroidChrome(to.path)
  } else {
    // なにもしない.
  }
}

/**
 * アクセスされたUserAgentを解析する
 *
 * @return {Object} UserAgentをパースした結果 { isAndroidWebView: andoroidのWebViewか, isIOSWebView: iOSのWebViewか, isSupportBrowser: サポートブラウザか}
 */
const parseUserAgent = () => {
  const rowUa = window.navigator.userAgent || ''
  const ua = rowUa.toLowerCase().trim()

  const isAndroid = /android/.test(ua)
  const isIOS = /iphone|ipod|ipad/.test(ua)
  const isWebView = /fb_iab|fban|messenger|line\/|yjapp/.test(ua)

  const isAndroidWebView = isAndroid && isWebView
  const isIOSWebView = isIOS && isWebView

  return {
    isAndroidWebView: isAndroidWebView,
    isIOSWebView: isIOSWebView,
    isSupportBrowser: !(isIOSWebView || isAndroidWebView)
  }
}

/**
 * 閲覧しようとしたページをandroidのchromeで表示する
 *
 * @param {String} path 閲覧しようとしたページのパス
 */
const openAndroidChrome = path => {
  const hostPath = location.host + path
  window.location.href = 'intent://' + hostPath + '/#Intent;scheme=https;action=android.intent.action.VIEW;package=com.android.chrome;end'
}
