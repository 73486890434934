import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import functions from '@/store/modules/functions'
import cloudstorage from '@/store/modules/cloudstorage'
import users from '@/store/modules/users'
import discordUsers from '@/store/modules/discordUsers'
import exemptions from '@/store/modules/exemptions'
import tickets from '@/store/modules/tickets'
import payments from '@/store/modules/payments'
import tokens from '@/store/modules/payments/tokens'
import cards from '@/store/modules/payments/cards'
import plans from '@/store/modules/payments/plans'
import subscriptions from '@/store/modules/payments/subscriptions'
import subscriptionSchedule from '@/store/modules/payments/subscriptionSchedule'
import cancels from '@/store/modules/cancels'
import invitationHistories from '@/store/modules/invitationHistories'
import settingInvitation from '@/store/modules/settingInvitation'
import settingImages from '@/store/modules/settingImages'
import settingPrivileges from '@/store/modules/settingPrivileges'
import settingGeneral from '@/store/modules/settingGeneral'
import settingGroup from '@/store/modules/settingGroup'
import settingDisplayed from '@/store/modules/settingDisplayed'
import settingRepresentatives from '@/store/modules/settingRepresentatives'
import settingTickets from '@/store/modules/settingTickets'
import faqs from '@/store/modules/faqs'
import settingMovies from '@/store/modules/settingMovies'
import settingFooters from '@/store/modules/settingFooters'
import settingButtons from '@/store/modules/settingButtons'
import settingSignups from '@/store/modules/settingSignups'
import guests from '@/store/modules/guests'
import loginReferrer from '@/store/modules/loginReferrer'
import stops from './modules/stops'
import maintenances from './modules/maintenances'

Vue.use(Vuex)

const modules = { auth, functions, cloudstorage, users, discordUsers, exemptions, tickets, payments, tokens, cards, plans, subscriptions, subscriptionSchedule, cancels, invitationHistories, settingInvitation, settingGeneral, settingGroup, settingImages, settingPrivileges, settingDisplayed, settingRepresentatives, settingTickets, faqs, settingMovies, settingFooters, settingButtons, settingSignups, guests, stops, maintenances, loginReferrer }

export default new Vuex.Store({
  modules: modules,
  state: {
    isProcessing: true,
    isSubmitting: false,
    isAuthProcessing: true,
    isSupportBrowser: false,
    redirectPath: null,
    referrerPath: null,
    queryParameter: '',
    telop: {
      show: false,
      msg: '',
      type: ''
    },
    activeUIDs: [],
    MAX_LENGTH: {
      USER: {
        name: 20,
        position: 20,
        profile: 260
      }
    },
    /**
     * 入力項目に対するバリデーション定義
     * - valid: 有効な値
     * - requiredBlank: 必須項目が未入力
     * - overLength: 規定された文字数を超えている
     * - typeInvalid: 書式が無効 (例: link)
     */
    VALIDATION_DEFINITION: {
      valid: 'valid',
      requiredBlank: 'requiredBlank',
      overLength: 'overLength',
      typeInvalid: 'typeInvalid'
    }
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 処理中かどうか
     */
    isProcessing: state => state.isProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} フォーム等の送信中かどうか
     */
    isSubmitting: state => state.isSubmitting,
    /**
     * UserAgentがWebView(LINE/Messangerのみ)でないかの判定結果
     *
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 動作対象のブラウザであること
     */
    isSupportBrowser: state => state.isSupportBrowser,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} Auth処理中かどうか
     */
    isAuthProcessing: state => state.isAuthProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURL
     */
    redirectPath: state => state.redirectPath,
    /**
     * 動作対象外ブラウザページでコピーしたいURLのパス
     *
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} コピー対象のパス (/<path名>)
     */
    referrerPath: state => state.referrerPath,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURLのパラメータ
     */
    queryParameter: state => state.queryParameter,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} テロップの情報
     */
    telop: state => state.telop,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Array} アクティブなユーザーのuid一覧
     */
    activeUIDs: state => state.activeUIDs,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Objcet} 格納できる文字数の最大値
     */
    MAX_LENGTH: state => state.MAX_LENGTH,
    /**
     * @param {Objcet} state 暗黙的に受け取るstate
     * @return {Object} バリデーション結果の定義
     */
    VALIDATION_DEFINITION: state => state.VALIDATION_DEFINITION
  },
  mutations: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isAuthProcessing Auth処理中かどうか
     */
    setAuthProcessing: (state, isAuthProcessing) => {
      state.isAuthProcessing = isAuthProcessing
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isProcessing 処理中かどうか
     */
    setProcessing: (state, isProcessing) => {
      state.isProcessing = isProcessing
      if (state.isSubmitting) state.isSubmitting = false
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isSupportBrowser 動作対象のブラウザか
     */
    setSupportBrowser: (state, isSupportBrowser) => {
      state.isSupportBrowser = isSupportBrowser
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isSubmitting フォーム等の送信中かどうか
     */
    setSubmitting: (state, isSubmitting) => {
      state.isSubmitting = isSubmitting
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path アクセスされたURL
     */
    setRedirectURL: (state, path) => {
      state.redirectPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path referrerPath(クエリ名)で指定されたURLのパス
     */
    setReferrerPath: (state, path) => {
      state.referrerPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} queryParameter アクセスされたURLのパラメータ
     */
    setQueryParameter: (state, queryParameter) => {
      state.queryParameter = queryParameter
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} telop テロップの情報
     * @param {Boolean} telop.show テロップ表示の有無
     * @param {String} telop.msg テロップの内容
     * @param {String} telop.type テロップの色 success, warning, error
     */
    setTelop: (state, telop) => {
      state.telop = telop
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String[]} activeUIDs アクティブユーザーの一覧
     */
    setActiveUIDs: (state, activeUIDs) => {
      state.activeUIDs = activeUIDs
    }
  },
  actions: {
    /**
     * 全モジュールのstore情報をリセットする
     */
    resetState: ({ commit }) => {
      Object.keys(modules).forEach(key => {
        // setting系の情報は操作対象によって変化しないので、リセットせずに残したままにする
        const unresetKey = ['settingGeneral', 'settingDisplayed', 'settingImages', 'settingPrivileges', 'settingFooters', 'settingMovies', 'settingButtons', 'settingSignups']

        // stateにプロパティが存在するモジュールのresetStateを起動する
        if (Object.keys(modules[key].state).length !== 0 && !unresetKey.includes(key)) commit(key + '/resetState')
      })
    }
  }
})
