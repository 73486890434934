import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // Signupのテキスト情報一覧
    // { type: {}, type: {}, ... }
    settingSignups: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type Signupのテキスト情報の種類
   * @return {Object} Signupのテキスト情報
   */
  settingSignup: state => type => state.settingSignups[type] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} Signupのテキスト情報の設定内容全て
   */
  settingSignups: state => state.settingSignups
}

const mutations = {
  /**
   * Signupのテキスト情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.type Signupのテキスト情報の種類
   * @param {Object} payload.settingSignup Signupのテキスト情報
   */
  setSettingSignup: (state, payload) => {
    state.settingSignups = Object.assign({}, state.settingSignups, { [payload.type]: payload.settingSignup })
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * Signupのテキスト情報を取得
   */
  getSettingSignups: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_signup')
        .orderBy('createdAt', 'desc')
        .get()

      snapshot.docs.forEach(doc => {
        commit('setSettingSignup', { type: doc.data().type, settingSignup: Object.assign(doc.data(), { ssid: doc.id }) })
      })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
