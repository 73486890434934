import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 許可されたreferrerのdomain一覧
    domains: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} 許可されたreferrerの一覧
   */
  domains: state => state.domains
}

const mutations = {
  /**
   * 許可されたreferrerの一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String[]} domains 許可されたreferrerの一覧
   */
  setUrls: (state, domains) => {
    domains.forEach(domain => state.domains.push(domain))
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 許可されたreferrerのURL一覧を取得する
   */
  getLoginReferrer: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('login_referrer')
        .orderBy('updatedAt', 'desc')
        .limit(1)
        .get()
      const doc = snapshot.empty ? null : snapshot.docs[0]
      if (doc) commit('setUrls', doc.data().domains)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
