import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    plan: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} プラン情報
   */
  plan: state => state.plan
}

const mutations = {
  /**
   * プラン情報のセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} plan プラン情報
   */
  setPlan: (state, plan) => {
    state.plan = plan
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 新しいプラン情報の作成
   * @param {Object} payload 引数
   * @param {String} payload.uid ユーザーID
   * @param {String} payload.planID プランID
   */
  createPlan: async ({ commit }, payload) => {
    try {
      const plan = { amount: Number(process.env.VUE_APP_PRICE), planID: payload.planID, createdAt: new Date() }
      await firestore.collection('payments')
        .doc(payload.uid)
        .collection('plans')
        .add(plan)
      commit('setPlan', plan)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 最新のプラン情報の取得
   * @param {String} uid ユーザーID
   */
  getPlan: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore.collection('payments')
        .doc(uid)
        .collection('plans')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      const doc = snapshot.docs[0]
      if (doc) commit('setPlan', Object.assign(doc.data(), { pid: doc.id }) )
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
