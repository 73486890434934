import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 基本情報の設定内容一覧
    // { type: {}, type: {}, ... }
    settingGeneral: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type 基本情報の設定内容の種類
   * @return {Object} 基本情報の設定内容
   */
  settingGeneral: state => type => state.settingGeneral[type] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 基本情報の設定内容全て
   */
  settingGeneralAll: state => state.settingGeneral
}

const mutations = {
  /**
   * 基本情報の設定内容をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.type 基本情報の設定内容の種類
   * @param {Object} payload.settingGeneral 基本情報の設定内容
   */
  setSettingGeneral: (state, payload) => {
    state.settingGeneral = Object.assign({}, state.settingGeneral, { [payload.type]: payload.settingGeneral })
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 基本情報の設定内容を取得
   * @param {String} type 基本情報の設定内容の種類
   */
  getSettingGeneral: async ({ commit }, type) => {
    try {
      const snapshot = await firestore
        .collection('setting_general')
        .where('type', '==', type)
        .get()
      snapshot.forEach(doc => {
        commit('setSettingGeneral', { type: type, settingGeneral: doc.data() })
      })
    } catch {
      router.push({ name: 'error' })
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
