import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全講師のオブジェクト一覧
    // { uid: {}, uid: {}, ... }
    guests: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 全講師情報
   */
  guests: state => state.guests
}

const mutations = {
  /**
   * 講師情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid 講師ID
   * @param {Object} payload.guest 講師のオブジェクト
   */
  setGuest: (state, payload) => {
    state.guests = Object.assign({}, state.guests, { [payload.uid]: payload.guest })
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 全講師情報を取得
   */
  getGuests: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('guests')
        .get()
      snapshot.forEach(doc => {
        commit('setGuest', { uid: doc.id, guest: doc.data() })
      })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
