import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 表示有無情報の一覧
    settingDisplayed: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 表示有無情報の設定内容
   */
  settingDisplayed: state => state.settingDisplayed,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type 表示有無情報の種類
   * @return {Boolean} 表示有無
   */
  isDisplayed: state => type => state.settingDisplayed[type] ? state.settingDisplayed[type].isDisplayed : false
}

const mutations = {
  /**
   * 表示有無情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} settingDisplayed ホームへの表示内容
   */
  setSettingDisplayed: (state, settingDisplayed) => {
    state.settingDisplayed.push(settingDisplayed)
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
     * 表示有無情報を取得
     */
  getSettingDisplayed: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('setting_displayed')
        .orderBy('createdAt', 'desc')
        .get()

      snapshot.forEach(doc => {
        commit('setSettingDisplayed', Object.assign({ sdid: doc.id }, doc.data()))
      })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default { namespaced: true, state, getters, actions, mutations }
