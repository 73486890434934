import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 最新の停止情報
    stop: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新の停止情報
   */
  stop: state => state.stop
}

const mutations = {
  /**
   * 停止情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} stop 停止情報のオブジェクト
   */
  setStop: (state, stop) => {
    state.stop = stop
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * 停止情報の監視
   */
  onStop ({ commit, state }) {
    firestore.collection('stops').orderBy('createdAt', 'desc').limit(1).onSnapshot(querySnapshot => {
      for (const change of querySnapshot.docChanges()) {
        // 過去のメンテナンス情報の取得
        const beforeStop = state.stop
        // 現在のメンテナンス情報に更新
        const afterStop = change.doc.data()
        commit('setStop', afterStop)

        if (!afterStop.endAt) {
          // 終了時刻が設定されてないので、メンテナンス開始
          commit('setRedirectURL', '/stop', { root: true })
          router.push({ name: 'stop' })

          // onAuthが動かないので、ここでprocessingをfalseにしておく
          commit('setAuthProcessing', false, { root: true })
        } else if (beforeStop && !beforeStop.endAt && afterStop.endAt) {
          // 変更前が終了時刻なしかつ最新がある状態なので、メンテナンス終了
          // App.vueのmountedを再読み込みさせるためにリロードさせる
          window.location.href = '/home'
        }
      }
    }, () => {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
