/**
 * original function library
 * [usage]
 * import discord from '@/assets/lib/discord'
 * mixins: [discord]
 */
import Vue from 'vue'

const DISCORD_OAUTH_BASE_URL = 'https://discord.com/api/oauth2/authorize'
const DISCORD_OAUTH_PARAM = 'response_type=code&scope=identify guilds.join'

export default Vue.extend({
  methods: {
    /**
     * OAuth認証画面のURLを作成する
     * @return {String} OAuth認証画面のURL
     */
    createOAuthURL () {
      const clientID = process.env.VUE_APP_DISCORD_CLIENT_ID
      const redirectURI = encodeURIComponent(`${window.location.protocol}//${window.location.host}/discord/`)
      return `${DISCORD_OAUTH_BASE_URL}?${DISCORD_OAUTH_PARAM}&client_id=${clientID}&redirect_uri=${redirectURI}`
    }
  }
})
