import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    groupName: null,
    groupURL: null,
    discordServerID: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} 限定グループ名
   */
  groupName: state => state.groupName || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} getters 暗黙的に受け取るgetters
   * @param {Object} rootState 暗黙的に受け取るrootState
   * @param {Object} rootGetters 暗黙的に受け取るrootGetters
   * @return {String} 限定グループのURL
   * @see 限定グループの参加時にURLに付与されたuidの文字列と一致したときに認証OKと判定するためにuidを付与してます。
   */
  groupURL: (state, getters, rootState, rootGetters) => state.groupURL ? state.groupURL + '?uid=' + rootGetters['auth/uid'] : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} Discord連携をしているかどうか
   */
  discordLinkage: state => Boolean(state.discordServerID),
  /**
   * 限定グループの設定が現在されているかを判定する
   * グループ名とグループURLいずれかが存在していれば設定されている判定とする
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 限定グループ設定の状態
   */
  isSetGroupSetting: state => {
    return ['groupName', 'groupURL']
      .map(key => Boolean(state[key]))
      .reduce((previous, current) => previous || current)
  }
}

const mutations = {
  /**
   * 限定グループの設定を種類に基づいてセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload セットされる限定グループの設定オブジェクト
   * @param {String} payload.type セットされる限定グループの設定項目の種類
   * * @param {String} payload.value セットされる限定グループの設定項目の値
   */
  setGroupSetting: (state, payload) => {
    state[payload.type] = payload.value
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * typeに紐づく限定グループの設定情報オブジェクトを取得しstoreを更新する
   * @param {String} type 限定グループの設定項目名
   */
  getGroupSetting: async ({ commit }, type) => {
    try {
      const snapshot = await firestore
        .collection('setting_general')
        .where('type', '==', type)
        .limit(1)
        .get()

      const doc = snapshot.empty ? null : snapshot.docs[0]
      if (doc) commit('setGroupSetting', Object.assign({}, { type: type, value: doc.data().value }))
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
