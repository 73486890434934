import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // ボタン情報一覧
    // { type: {}, type: {}, ... }
    settingButtons: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} type ボタン情報の種類
   * @return {Object} ボタン情報
   */
  settingButton: state => type => state.settingButtons[type] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} ボタン情報の設定内容全て
   */
  settingButtons: state => state.settingButtons
}

const mutations = {
  /**
   * ボタン情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.type ボタン情報の種類
   * @param {Object} payload.settingButton ボタン情報
   */
  setSettingButton: (state, payload) => {
    state.settingButtons = Object.assign({}, state.settingButtons, { [payload.type]: payload.settingButton })
  },
  /**
   * stateのリセット
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定種類のボタン情報を取得
   * @param {String} type ボタン情報の種類
   * @param {String} category ボタン情報のカテゴリ
   */
  getSettingButton: async ({ commit }, { type, category }) => {
    try {
      const snapshot = await firestore
        .collection('setting_button')
        .where('category', '==', category)
        .where('type', '==', type)
        .get()

      const doc = snapshot.empty ? null : snapshot.docs[0]
      if (doc) commit('setSettingButton', { type: type, settingButton: Object.assign(doc.data(), { sbid: doc.id }) })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
