<template>
  <div class="home-menu">
    <div class="home-menu__elem" v-for="(menu, index) in orderedMenu.concat(unorderedMenu)" :key="menu.title + index">
      <a v-if="menu.url && !menu.password" class="home-menu__elem__link" :href="menu.url" target="_blank" rel="noopener noreferrer" />
      <div v-else-if="menu.url" class="home-menu__elem__link" @click="$refs.confirm.open(menu.password, menu.pwdTxt, menu.url)" />
      <router-link v-else class="home-menu__elem__link" :to="{ name: menu.to }" />
      <v-btn class="home-menu__elem__btn" icon>
        <v-icon class="home-menu__elem__btn__icon">{{ menu.icon }}</v-icon>
      </v-btn>
      <p class="home-menu__elem__title">{{ menu.title }}</p>
    </div>
    <password-confirm ref="confirm" />
  </div>
</template>

<script>
import discord from '@/assets/lib/discord'
import PasswordConfirm from '@/components/home/confirm'

export default {
  components: { PasswordConfirm },
  mixins: [discord],
  computed: {
    /**
     * @return {Object[]} 表示内容
     */
    menus () {
      // 会員一覧、回数券の場合の内部遷移先
      const to = {
        member: 'member',
        ticket: 'ticket'
      }

      // 遷移先情報を更新
      return this.settingDisplayed.map(setting => {
        if (setting.type === 'group') {
          // Discord連携済みかつまだDiscordに参加していないユーザーに対しては参加用URLを表示させる
          if (this.isDiscordLinkage && !this.isJoinedDiscordServer) {
            setting.title = '限定グループに参加'
            setting.url = this.createOAuthURL()
          } else {
            setting.url = this.groupURL
          }
        }
        if (Object.keys(to).includes(setting.type)) setting.to = to[setting.type]

        return setting
      })
    },
    /**
     * @return {String[]} 順番固定されたメニュー名（オリジナルURL以外）
     */
    ordered () {
      let ordered = ['group', 'member', 'ticket']
      if (!this.isSetGroupSetting) ordered = ordered.filter(type => type !== 'group')
      if (!this.tickets) ordered = ordered.filter(type => type !== 'ticket')
      return ordered
    },
    /**
     * @return {Object[]} 表示順の決まっているメニュー
     */
    orderedMenu () {
      const orderedMenu = []
      this.ordered.forEach(type => {
        const menu = this.menus.find(menu => menu.type === type)
        if (menu) orderedMenu.push(menu)
      })
      return orderedMenu
    },
    /**
     * @return {Object[]} 表示順の決まっているメニュー
     */
    unorderedMenu () {
      return this.menus.filter(menu => menu.type === 'original')
    },
    /**
     * @return {Boolean} 限定グループ設定の状態
     */
    isSetGroupSetting () {
      return this.$store.getters['settingGroup/isSetGroupSetting']
    },
    /**
     * @return {String} 限定グループのURL
     */
    groupURL () {
      return this.$store.getters['settingGroup/groupURL']
    },
    /**
     * @return {Object[]} 表示有無の設定情報
     */
    settingDisplayed () {
      return this.$store.getters['settingDisplayed/settingDisplayed']
    },
    /**
     * @return {Object} 今月発行済み回数券情報を取得
     */
    tickets () {
      return this.$store.getters['tickets/tickets']
    },
    /**
     * @return {Boolean} Discord連携しているかどうか
     */
    isDiscordLinkage () {
      return this.$store.getters['settingGroup/discordLinkage']
    },
    /**
     * @return {Boolean} Discordサーバーに参加済みかどうか
     */
    isJoinedDiscordServer () {
      return this.$store.getters['discordUsers/isJoined']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";
@import "@/assets/sass/fontfamily.scss";

.home-menu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &__elem {
    position: relative;
    width: calc(50% - 5px);
    padding: 27px 0;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 4px rgb(0 0 0 / 25%);
    &:nth-child(2n) {
      margin-left: 10px;
    }
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      z-index: $hover_btn_zindex;
      width: 100%;
      height: 100%;
    }
    &__btn {
      &.v-btn--icon.v-size--default {
        width: 32px;
        height: 32px;
      }
      &.theme--light.v-btn {
        color: $black_lighten_color;
      }
      &__icon {
        &.v-icon {
          width: 32px;
          height: 32px;
          font-size: 3.2rem !important;
        }
      }
    }
    &__title {
      margin: 15px 0 0;
      font-size: 1.4rem;
    }
  }
}
</style>
