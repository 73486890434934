import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // ログインユーザーのDiscordサーバーへの参加状況
    isJoined: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} Discordサーバーへの参加状況
   */
  isJoined: state => Boolean(state.isJoined)
}

const mutations = {
  /**
   * Discordサーバーへの参加状況を更新する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isJoined Discordサーバーへの参加状況
   */
  setIsJoined (state, isJoined) {
    state.isJoined = isJoined
  }
}

const actions = {
  /**
   * Discordユーザー情報を取得する
   * @param {String} uid サブスキューのユーザーID
   */
  getDiscordUser: async ({ commit }, uid) => {
    try {
      const doc = await firestore
        .collection('discord_users')
        .doc(uid)
        .get()
      const discordUser = doc.exists ? doc.data() : null
      if (discordUser) commit('setIsJoined', discordUser.isJoined)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
