/**
 * original founction library
 * [usage]
 * import url from '@/assets/lib/url'
 * mixins: [url]
 */
import Vue from 'vue'

// 動画用のドメインと埋め込みURL
const EMBED_LIST = {
  youtube:
    {
      urls: {
        original: 'https://youtu.be/',
        embed: 'https://www.youtube.com/embed/'
      },
      queries: {}
    },

  vimeo:
    {
      urls: {
        original: 'https://vimeo.com/',
        embed: 'https://player.vimeo.com/video/'
      },
      // @see https://vimeo.zendesk.com/hc/ja/articles/360001494447-%E3%83%97%E3%83%AC%E3%83%BC%E3%83%A4%E3%83%BC%E3%81%AE%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B
      queries: { title: 0, byline: 0 }
    }

}

export default Vue.extend({
  methods: {
    /**
      * 文字列に含まれるURL部分をリンクに変換する
      * @param {String} str 対象の文字列
      * @return {String} URL部分がリンクに変換された文字列
      */
    createAutolink (str) {
      const regexpUrl = /(((?:\w+:)?\/\/)?(\S){1,}\.[a-zA-Z]{2,}(\/(\S){1,})?\/?)/g
      const regexpProtocol = /(https?:\/\/)/g
      // ユーザーの投稿した文字列をサニタイズ
      const sanitizedStr = this.$sanitize(str)

      // 文字列からURL該当箇所を検出してリンク作成
      return sanitizedStr.replace(regexpUrl, (...args) => {
        let href = ''
        if (args[0].match(regexpProtocol)) {
          // URLにプロトコル「http(s)://」がある場合
          // 例：https://google.com
          href = args[0]
        } else {
          if (args[0].match('//')) {
            // URLに不完全なプロトコルがある場合
            // 例：ttps://google.com
            const protocol = args[0].match('p://') ? 'http://' : 'https://'
            href = protocol + args[0].split('//')[1]
          } else {
            // URLにプロトコルがない場合
            // 例：google.com
            href = 'https://' + args[0]
          }
        }

        return '<a class="textlink" href="' + href + '" target="_blank" rel="noopener noreferrer">' + args[0] + '</a>'
      })
    },
    /**
     * 正しいURLフォーマットか判定する
     * 具体的なパターンマッチの確認がしたい場合は下記サイトで入力
     * @see https://www.regextester.com/?fam=117238
     * @param {String} url 対象のURL
     * @return {Boolean} URLフォーマットが正しいかどうか
     */
    isValidURL (url) {
      // 引数がString型じゃない場合はfalseを返す
      if (!(typeof (url) == 'string' || url instanceof String)) return false

      const validUrlFormat = /^((http|https):\/\/(\S){1,}\.[a-zA-Z]{2,}(\/(\S){1,}|\/)?)$/g
      return !!url.match(validUrlFormat)
    },
    /**
     * YouTube/VimeoのURLからIDを抽出し、埋め込み用のURLに変換する
     * @param {String} url
     * @return {String} 埋め込み形式に変換済みのURL
     */
    convertEmbedURL (url) {
      // ドメイン名の取得
      const serviceName = Object.keys(EMBED_LIST).find(service => url.match(EMBED_LIST[service].urls.original))
      if (!serviceName) {
        return undefined
      }

      // URLのオプションを生成
      const queries = Object.keys(EMBED_LIST[serviceName].queries).map(key => [key, EMBED_LIST[serviceName].queries[key]].join('=')).join('&')

      // 動画IDを取得し、埋め込みURLを生成
      const movieID = url.split(EMBED_LIST[serviceName].urls.original)[1]
      const convertedURL = EMBED_LIST[serviceName].urls.embed + movieID + (queries.length > 0 ? '?' + queries : '')

      return convertedURL
    },
    /**
     * URLからスキームを削除する
     * @param {String} url
     * @return {String} urlからスキームを削除した文字列
     */
    removeScheme (url) {
      const uri = new URL(url)
      return uri.pathname === '/' ? uri.hostname : uri.hostname + uri.pathname
    }
  }
})
